<template>
  <div>
    <v-container>
      <v-alert dense text type="info">
        Sekarang kamu bisa menghubungi langsung penyelenggara tournament. Yuk
        mulai obrolanmu!
      </v-alert>
      <chat />
    </v-container>
  </div>
</template>

<script>
import Chat from "../components/chat/Chat.vue";
import socket from "../socket";

export default {
  name: "App",
  components: {
    Chat,
  },
  mounted() {
    this.checkIsLogin();
    socket.disconnect();
    socket.connect();
  },
  head() {
    return {
      title: `Obrolan`,
    };
  },
  methods: {
    checkIsLogin() {
      if (
        this.$store.getters.getToken == null ||
        this.$store.getters.getToken == ""
      ) {
        this.$router.push("/");
      }
    },
  },
};
</script>

