<template>
  <div>
    <div class="header">
      <status-icon :connected="user.connected == 'true' ? 1 : 0" />{{
        user.userData.fullName
      }}
      <i
        style="color: #0356a1"
        class="bx bx-badge-check align-self-center"
        v-if="user.userData.isVerified === 1"
      ></i>
    </div>
    <v-list id="container">
      <v-list-item v-for="(message, index) in user.messages" :key="index">
        <v-list-item-content
          :class="{ 'd-flex flex-row-reverse': message.fromSelf }"
        >
          <div
            v-if="message.fromSelf"
            class="text-right text-wrap black--text"
            style="
              padding: 12px;
              max-width: 300px;
              background-color: rgb(248, 179, 43, 0.24);
              borderradius: 8px 0px 8px 8px;
            "
          >
            <img
              v-viewer
              :src="`${baseImageUrl}/${message.attachment_url}`"
              width="100%"
              v-if="message.attachment_url"
              height="178px"
              style="object-fit: cover; border-radius: 8px"
              class="mr-4 cursor"
            />
            {{ message.content }}
          </div>

          <div
            v-else
            class="text-wrap"
            style="
              padding: 12px;
              max-width: 300px;
              border: 1px solid #79797c;
              border-radius: 0px 8px 8px 8px;
            "
          >
            <img
              v-viewer
              :src="`${baseImageUrl}/${message.attachment_url}`"
              width="100%"
              v-if="message.attachment_url"
              height="178px"
              style="object-fit: cover; border-radius: 8px"
              class="mr-4 cursor"
            />
            {{ message.content }}
          </div>

          <div
            class="text-caption"
            :class="{ 'd-flex flex-row-reverse': message.fromSelf }"
          >
            {{ message.fromSelf ? "Anda" : user.userData.fullName }} |
            {{ message.fromSelf ? message.read_status : "" }}
            {{ new Date(parseInt(message.timestamp)).toLocaleString("id-ID") }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!-- <v-sheet class="mx-auto" max-width="700">
      <v-slide-group multiple show-arrows>
        <v-slide-item  v-for="(message, index) in quick_chat" :key="index">
          <v-btn
            class="mx-2"
            depressed
            rounded
            @click="onSubmitQuickChat(message)"
          >
            {{message}}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-sheet> -->

    <input
      truncate-length="15"
      id="fileInput"
      @change="onFileChange($event)"
      v-show="hidden"
      type="file"
      accept="image/png, image/jpg, image/jpeg"
    />

    <v-card v-if="url" style="background-color: rgb(248, 179, 43, 0.24)">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title>Attachment</v-card-title>
          <v-card-subtitle
            >Gambar telah dipilih dan akan dikirimkan bersama dengan pesan
            kamu</v-card-subtitle
          >

          <v-card-actions>
            <v-btn
              @click="onDeleteFile"
              class="ml-2 mt-5"
              outlined
              rounded
              small
            >
              <v-icon dark> mdi-delete </v-icon>
              Hapus
            </v-btn>
          </v-card-actions>
        </div>

        <v-avatar class="ma-5" size="125" tile>
          <v-img :src="url"></v-img>
        </v-avatar>
      </div>
    </v-card>

    <form @submit.prevent="onSubmit" class="form">
      <v-row class="ma-5">
        <v-col cols="12" sm="11" md="11">
          <!-- <v-textarea
            solo
            dense
            textarea
            rows="3"
            v-model="input"
            placeholder="Tulis pesan..."
          ></v-textarea> -->
          <v-text-field
            outlined
            append-icon="mdi-camera"
            v-model="input"
            placeholder="Tulis pesan..."
            @click:append="openUploadFile"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <!-- <v-btn
            type="button"
            fab
            small
            style="background-color: #f8b32b"
            elevation="2"
            :disabled="url"
            @click="openUploadFile"
            ><v-icon dark> mdi-camera </v-icon></v-btn
          > -->
          <v-btn
            type="submit"
            fab
            style="background-color: #f8b32b"
            :disabled="!isValid"
            elevation="2"
            ><v-icon color="white"> mdi-send </v-icon></v-btn
          >
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import StatusIcon from "./StatusIcon.vue";
import axios from "axios";
import * as imageConversion from "image-conversion";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";

export default {
  name: "MessagePanel",
  components: {
    StatusIcon,
    directives: {
      viewer: viewer({
        debug: true,
      }),
    },
  },
  props: {
    user: Object,
  },
  data() {
    return {
      baseImageUrl: process.env.VUE_APP_IMAGE_URL,
      url: null,
      isLoading: false,
      input: "",
      quick_chat: [
        "Halo, ingin bertanya",
        "Apakah tournament ini gratis?",
        "Bisakah saya mendapatkan bukti keikutsertaan saya?",
      ],
    };
  },
  methods: {
    onSubmit() {
      if (this.attachment) {
        this.onUploadAttachment();
      } else {
        this.$emit("input", this.input);
        this.input = "";
        this.onDeleteFile();
      }
    },
    onSubmitQuickChat(chat) {
      this.$emit("input", chat);
    },
    scrollToEnd() {
      var container = this.$el.querySelector("#container");
      container.scrollTop = container.scrollHeight;
    },
    openUploadFile() {
      document.getElementById("fileInput")?.click();
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const fileSize = file.size / 1024;
        if (fileSize > 7168) {
          this.$toast.warning("Silahkan masukkan foto tidak lebih dari 7mb");
          return;
        }

        const convert = await imageConversion.compressAccurately(file, 300);
        const new_file = new File([convert], file.name, { type: convert.type });
        this.attachment = new_file;
        if (
          new_file["type"] === "image/jpeg" ||
          new_file["type"] === "image/png" ||
          new_file["type"] === "image/jpg" ||
          new_file["type"] !== "application/pdf"
        ) {
          this.url = URL.createObjectURL(new_file);
          // this.onAddGalery();
        } else {
          this.$toast.warning("Silahkan masukkan foto dalam format jpeg/png");
        }
      }
    },
    onDeleteFile() {
      this.url = null;
      this.attachment = null;
      document.getElementById("fileInput").value = null;
    },
    async onUploadAttachment() {
      this.isLoading = true;
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };

      const form = new FormData();

      form.append("attachment", this.attachment);

      try {
        let response = await axios
          .create({ withCredentials: false })
          .post(`${process.env.VUE_APP_API_URL}/v1/add_attachment`, form, config);
        this.$emit("input", this.input, response.result);
        this.$toast.success(response.message);
        this.input = "";
        this.onDeleteFile();
      } catch (error) {
        this.$toast.warning(error.response.data["message"]);
        if (error.response.status === 500) {
          this.$sentry.captureException(error);
        }
      }
      this.isLoading = false;
    },
  },
  computed: {
    isValid() {
      return this.input.length > 0 || this.url;
    },
  },
  mounted() {
    this.scrollToEnd();
  },
  updated() {
    this.scrollToEnd();
  },
};
</script>

<style scoped>
.header {
  line-height: 40px;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd;
}

.v-list {
  height: 400px;
  overflow-y: auto;
}
</style>
