<template>
  <v-card elevation="2">
    <v-row no-gutters>
      <v-col style="max-height: 100%" cols="12" md="4" sm="6" xs="12">
        <v-subheader style="font-size: 20px; font-weight: 500"
          >Chat</v-subheader
        >
        <v-text-field
          hide-details
          label="Cari Obrolan"
          prepend-inner-icon="mdi-magnify"
          name="search"
          class="mx-1"
          single-line
          outlined
          v-model="searchKeyword"
        ></v-text-field>
        <p v-if="filtered.length == 0" class="text-center ma-10">
          Obrolan tidak ditemukan
        </p>
        <v-list class="overflow-y-auto pa-4" style="max-height: 100%">
          <v-list-item-group color="#FDEDCE">
            <v-list-item
              v-for="user in filtered"
              :key="user.userID"
              :user="user"
              :selected="selectedUser === user"
              :style="`background-color:${
                selectedUser === user ? '#FDEDCE;' : 'white;'
              } border-radius:8px; color:${
                selectedUser === user ? '#79797C;' : 'black;'
              }`"
              @click="onSelectUser(user.userID)"
            >
              <v-list-item-icon>
                <v-avatar>
                  <img
                    :src="baseImageUrl + user.userData.profilePictureUrl"
                    :style="{ objectFit: 'cover' }"
                  />
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-badge
                    color="red"
                    :content="
                      user.messages.filter(function (el) {
                        return el.read_status == 'unread' && !el.fromSelf;
                      }).length
                    "
                    v-if="user.hasNewMessages"
                    ><span style="#79797C"
                      >{{ user.userData.fullName }}
                      <i
                        style="color: #0356a1"
                        class="bx bx-badge-check align-self-center"
                        v-if="user.userData.isVerified === 1"
                      ></i
                    ></span>
                  </v-badge>
                  <span style="#79797C" v-else
                    >{{ user.userData.fullName }}
                    <i
                      style="color: #0356a1"
                      class="bx bx-badge-check align-self-center"
                      v-if="user.userData.isVerified === 1"
                    ></i
                  ></span>
                </v-list-item-title>
                <span
                  class="text-caption"
                  v-if="user.messages[user.messages.length - 1]"
                  >{{
                    user.messages[user.messages.length - 1].content.slice(
                      0,
                      30
                    ) +
                    (user.messages[user.messages.length - 1].content.length > 30
                      ? "..."
                      : "")
                  }}
                  |
                  {{
                    new Date(
                      parseInt(
                        user.messages[user.messages.length - 1].timestamp
                      )
                    ).toLocaleString("id-ID")
                  }}</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" md="8" sm="6" xs="1">
        <message-panel
          id="chatPanel"
          v-if="selectedUser"
          :user="selectedUser"
          @input="onMessage"
        />
        <v-container v-else grey lighten-5 fill-height>
          <v-row justify="center text-center" align="center">
            <v-col align="center" justify="center" cols="12" sm="8">
              <v-img
                :src="require('../../assets/img/chat-icon.svg')"
                max-width="200px"
                class="align-self-center"
              ></v-img>
              <br />
              <span class="font-weight-black">Mari memulai obrolan!</span>
              <br />
              <span class="font-weight-light">
                Silakan memilih pesan untuk memulai percakapan
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import MessagePanel from "./MessagePanel.vue";
import socket from "../../socket";

export default {
  name: "Chat",
  components: { MessagePanel },
  data() {
    return {
      selectedUser: null,
      users: [],
      searchKeyword: "",
      baseImageUrl: process.env.VUE_APP_IMAGE_URL + "/",
    };
  },
  computed: {
    filtered() {
      return this.users.filter((v) =>
        v.userData.fullName && v.userData.fullName
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
      );
    },
  },
  methods: {
    onMessage(content, url) {
      if (this.selectedUser) {
        socket.emit("private message", {
          content,
          receiver: this.selectedUser.userID,
          attachment_url: url,
          read_status: "unread",
          name: this.$store.getters.getFullName,
          profilePictureUrl:
            process.env.VUE_APP_IMAGE_URL +
            this.$store.getters.getProfilePictureUrl,
        });
        this.selectedUser.messages.push({
          content,
          timestamp: Date.now(),
          fromSelf: true,
          attachment_url: url,
          read_status: "unread",
        });
        this.users.sort((a, b) => {
          if (a.messages.length > 0 && b.messages.length > 0)
            return (
              b.messages.at(-1)["timestamp"] - a.messages.at(-1)["timestamp"]
            );
        });
      }
    },
    onSelectUser(id) {
      this.users.forEach((user) => {
        if (user.userID == id) {
          user.hasNewMessages = false;
          user.messages.forEach((message) => {
            if (!message.fromSelf && message.read_status == "unread") {
              message.read_status = "read";
            }
          });
          this.selectedUser = user;
          setTimeout(this.scrollToChat, 500);
        }
      });

      //read
      socket.emit("read chat", {
        selectedUserId: id,
        isRead: "read",
      });
    },
    scrollToChat() {
      let element = document.getElementById("chatPanel");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    },
  },
  mounted() {
    socket.on("connect", () => {
      this.users.forEach((user) => {
        if (user.self) {
          user.connected = true;
        }
      });
    });

    socket.on("disconnect", () => {
      this.users.forEach((user) => {
        if (user.self) {
          user.connected = false;
        }
      });
    });

    const initReactiveProperties = (user) => {
      user.hasNewMessages = false;
    };

    socket.on("users", (users) => {
      // console.log(users);
      users.forEach((user) => {
        user.messages.forEach((message) => {
          // if (message.read_status == "unread") {
          //   user.hasNewMessages = true;
          // }

          message.fromSelf = message.sender === localStorage.getItem("id");

          if (!message.fromSelf && message.read_status == "unread") {
            user.hasNewMessages = true;
          }
        });
        for (let i = 0; i < this.users.length; i++) {
          const existingUser = this.users[i];
          if (existingUser.userID === user.userID) {
            existingUser.connected = user.connected;
            existingUser.messages = user.messages;
            return;
          }
        }
        user.self = user.userID === socket.userID;
        // initReactiveProperties(user);
        if (user.userID != localStorage.getItem("id")) {
          this.users.push(user);
        }
      });
      // put the current user first, and sort by username
      this.users.sort((a, b) => {
        // if (a.self) return -1;
        // if (b.self) return 1;
        // if (a.username < b.username) return -1;
        // return a.username > b.username ? 1 : 0;
        if (a.messages.length > 0 && b.messages.length > 0)
          return (
            b.messages.at(-1)["timestamp"] - a.messages.at(-1)["timestamp"]
          );
      });
      // console.log(this.users[0].messages[0].timestamp);

      if (this.$route.query.id && this.$route.query.id) {
        this.onSelectUser(this.$route.query.id);
      }
    });

    socket.on("user connected", (user) => {
      for (let i = 0; i < this.users.length; i++) {
        const existingUser = this.users[i];
        if (existingUser.userID === user.userID) {
          existingUser.connected = true;
          return;
        }
      }
      initReactiveProperties(user);
      this.users.push(user);
    });

    socket.on("user disconnected", (id) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        if (user.userID === id) {
          user.connected = false;
          break;
        }
      }
    });

    socket.on(
      "private message",
      ({
        content,
        sender,
        receiver,
        timestamp,
        attachment_url,
        read_status,
      }) => {
        for (let i = 0; i < this.users.length; i++) {
          const user = this.users[i];
          const fromSelf = socket.userID === sender;
          if (user.userID === (fromSelf ? receiver : sender)) {
            user.messages.push({
              content,
              fromSelf,
              timestamp,
              attachment_url,
              read_status,
            });
            if (user !== this.selectedUser) {
              user.hasNewMessages = true;
            } else {
              user.hasNewMessages = false;
              //read
              socket.emit("read chat", {
                selectedUserId: this.selectedUser.userID,
                isRead: "read",
              });
            }
            break;
          }
        }
        this.users.sort((a, b) => {
          if (a.messages.length > 0 && b.messages.length > 0)
            return (
              b.messages.at(-1)["timestamp"] - a.messages.at(-1)["timestamp"]
            );
        });
      }
    );

    socket.on("callback read chat", (data) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        if (user.userID == data.recipientId) {
          user.messages.forEach((message) => {
            if (message.fromSelf && message.read_status == "unread") {
              message.read_status = "read";
            }
          });
          break;
        }
      }
    });
  },
  destroyed() {
    socket.off("connect");
    socket.off("disconnect");
    socket.off("users");
    socket.off("user connected");
    socket.off("user disconnected");
    socket.off("private message");
    socket.off("read");
  },
};
</script>
<style scoped>
.v-list {
  height: 400px;
  overflow-y: auto;
}
</style>
